<template>
  <header class="st-Header">
    <div class="container">
      <div class="logo hidden"><a href="/"><img class="w-100 rendering" src="/assets/images/common/logo.png" alt="ちょこらびえんすすとあ"></a></div>
      <div class="nav-wrapper">
        <div class="nav-container">
          <nav>
            <div class="logo hidden"><a href="/"><img class="w-100 rendering" src="/assets/images/common/logo.png" alt="ちょこらびえんすすとあ"></a></div>
            <ul>
              <li class="d-lg-block">
                <a href="/">
                  <span class="icon-svg hidden">
                    <svg viewBox="0 0 48 55">
                      <use xlink:href="#icon-rabbit"></use>
                    </svg>
                  </span>HOME
                </a>
              </li>
              <li>
                <a href="/news">
                  <span class="icon-svg hidden">
                    <svg viewBox="0 0 48 55">
                      <use xlink:href="#icon-rabbit"></use>
                    </svg>
                  </span>NEWS
                </a>
              </li>
              <li>
                <a href="/goods">
                  <span class="icon-svg hidden">
                    <svg viewBox="0 0 48 55">
                      <use xlink:href="#icon-rabbit"></use>
                    </svg>
                  </span>GOODS
                </a>
              </li>
              <li>
                <a href="/member">
                  <span class="icon-svg hidden">
                    <svg viewBox="0 0 48 55">
                      <use xlink:href="#icon-rabbit"></use>
                    </svg>
                  </span>MEMBER
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="shopping">
        <ul>
          <li><a :href="cart+'/v2/member/MyPage.aspx?ccode=ccrb'"><img class="user" src="/assets/images/common/header/icon_user.svg" alt="User"></a><span>LOGIN</span></li>
          <li><a :href="cart+'/v2/ShoppingCart.aspx?scode=ccrb'"><img class="cart" src="/assets/images/common/header/icon_cart.svg" alt="Cart"></a><span>CART</span></li>
        </ul>
      </div>
      <div class="menu-vue canClick" type=""><span></span><span></span><span></span></div>
    </div>
  </header>
</template>

<style>
.hidden{
  visibility: hidden;
}
.st-Header .menu-vue {
    position: absolute;
    top: 28px;
    right: 30px;
    z-index: 20;
    width: 68px;
    height: 68px;
    background-image: url('../../public/assets/images/common/header/bg_menu.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: auto
}

@media screen and (min-width: 1025px) {
    .st-Header .menu-vue {
        display:none
    }
}

@media screen and (max-width: 768px) {
    .st-Header .menu-vue {
        top:3.64583vw;
        right: 3.90625vw;
        width: 12.76042vw;
        height: 12.76042vw
    }
}

@media(any-hover: hover) {
    .st-Header .menu-vue span {
        transition:background-color .2s
    }

    .st-Header .menu-vue:hover span {
        background-color: #fff
    }

    .st-Header .menu-vue:hover:before {
        -ms-transform: scale(1);
        transform: scale(1)
    }
}

.st-Header .menu-vue:before {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: -1;
    -ms-transform: scale(0);
    transform: scale(0);
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 100%;
    background-color: #ff5684;
    content: "";
    transition: .2s
}

@media screen and (max-width: 768px) {
    .st-Header .menu-vue:before {
        top:.52083vw;
        left: .52083vw;
        width: calc(100% - 1.04167vw);
        height: calc(100% - 1.04167vw)
    }
}

.st-Header .menu-vue span {
    position: absolute;
    left: 20px;
    width: 28px;
    height: 3px;
    border-radius: 1.5px;
    background-color: #ff5684
}

@media screen and (max-width: 768px) {
    .st-Header .menu-vue span {
        left:3.77604vw;
        width: 5.20833vw;
        height: .52083vw;
        border-radius: .26042vw
    }
}

.st-Header .menu-vue span:nth-child(1) {
    top: 24px
}

@media screen and (max-width: 768px) {
    .st-Header .menu-vue span:nth-child(1) {
        top:4.55729vw
    }
}

.st-Header .menu-vue span:nth-child(2) {
    top: 33px;
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

@media screen and (max-width: 768px) {
    .st-Header .menu-vue span:nth-child(2) {
        top:6.11979vw
    }
}

.st-Header .menu-vue span:nth-child(3) {
    top: 42px
}

@media screen and (max-width: 768px) {
    .st-Header .menu-vue span:nth-child(3) {
        top:7.68229vw
    }
}

.st-Header .menu-vue.open span {
    transition: top .2s .35s,left .2s .35s,transform .2s .35s,width .2s .35s,height .2s .35s,background-color .2s
}

.st-Header .menu-vue.opened:before {
    -ms-transform: scale(1);
    transform: scale(1)
}

.st-Header .menu-vue.opened span {
    top: 32px;
    left: 18px;
    width: 32px;
    height: 4px;
    border-radius: 1px;
    background-color: #fff
}

@media screen and (max-width: 768px) {
    .st-Header .menu-vue.opened span {
        top:5.98958vw;
        left: 3.38542vw;
        width: 5.98958vw;
        height: .65104vw;
        border-radius: .13021vw
    }
}

.st-Header .menu-vue.opened span:nth-child(1) {
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.st-Header .menu-vue.opened span:nth-child(2) {
    -ms-transform: scaleX(0);
    transform: scaleX(0)
}

.st-Header .menu-vue.opened span:nth-child(3) {
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}
</style>

<script>
import jQuery from 'jquery';
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require( 'jquery' )
export default {
  data(){
    return{
      path:this.$route.path,
      host: process.env.VUE_APP_IAM,
      cart: process.env.VUE_APP_CARTURI,
      cms: process.env.VUE_APP_CLIENTAPI,
    }
  },
  mounted(){
      setTimeout( this.showHead, 200 );
      jQuery( function ( $ ) {
        let scrollPos = 0;
        $('.st-Header .menu-vue').on('click', function() {
            $('.st-Header .menu-vue').prop('disabled', true);

            // ---------- Menu Open ---------- //
            if( !$('.st-Header .menu-vue').hasClass('open') ) {
            $('.st-Header .menu-vue').addClass('open');
            $('.nav-wrapper').addClass('open');
            $('.nav-container').addClass('show');

            scrollPos = $(window).scrollTop();
            $('body').addClass('fixed').css({'top': -scrollPos});

            setTimeout(function() {
                $('.st-Header .menu-vue').addClass('opened');
            }, 100);
            setTimeout(function() {
                $('.nav-wrapper').addClass('opened');
            }, 350);
            }
            // ---------- Menu Close ---------- //
            else {
            $('body').removeClass('fixed').css({'top': ''});
            $(window).scrollTop(scrollPos);

            $('.st-Header .menu-vue').removeClass('opened')
            $('.nav-wrapper').removeClass('open opened');
            setTimeout(function() {
                $('.nav-container').removeClass('show');
                $('.st-Header .menu-vue').removeClass('open');
            }, 550);
            }

            setTimeout(function() {
            $('.st-Header .menu-vue').prop('disabled', false);
            }, 350);
        });
      } );
  },
  methods:{
    showHead(){
      let target = document.getElementsByClassName('hidden')
      for(var i of target){
        i.style.visibility = 'visible'
      }
    },
  },
}
</script>
