<template>
	<span>
		<div class="st-Page_Header">
			<p class="title"><span class="en">GOODS</span><span class="ja">販売商品</span></p>
		</div>
		<div class="st-Page_Body">
			<article class="goods-Article">
				<div class="goods-image">
					<slick class="detailslider" ref="slick" :options="slickOptions" v-if="isSingleImg">
						<div class="slide-item"><img class="rendering" :src="imageSet(pList.images)"></div>
					</slick>
					<slick class="detailslider" ref="slick" :options="slickOptions" v-else>
						<div class="slide-item" v-for="(img,index) in pList.images" :key="img.index"><img class="rendering" :src="img.url" :alt="code+'-'+index"></div>
					</slick>
				</div>
				<div class="goods-header">
					<h1 class="title">{{title}}</h1>
					<p class="price">{{priceFilter(price)}}<span>円<span class="tax">（税込）</span></span></p>
				</div>
				<div class="goods-body">
					<table>
						<thead>
							<tr>
								<th class="type">種類</th>
								<th class="stock">在庫</th>
								<th class="quantity">数量</th>
								<th class="cart">&nbsp;</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(v,index) in variations" :key="index">
								<td class="type">{{v.title}}</td>
								<td class="stock">{{chkStock(v.productCode)}}</td>
								<td class="quantity">
									<div class="select">
										<button type="button" data-quantity="plus" @click="chgCount('up',index,v)"><span class="icon-svg">
											<svg viewBox="0 0 12 8">
												<use xlink:href="#icon-chevron_up"></use>
											</svg>
										</span></button>
										<button type="button" data-quantity="minus" @click="chgCount('down',index,v)"><span class="icon-svg">
											<svg viewBox="0 0 12 8">
												<use xlink:href="#icon-chevron_down"></use>
											</svg>
										</span></button>
										<select v-model="qtyBox[index]">
											<option v-for="q of qtyLimit(v)" :key="q.index" :value="q">{{q}}</option>
										</select>
									</div>
								</td>
								<td class="cart">
									<button class="sw-Button" type="button" :disabled="judgeStatus(v.productCode)" @click="cartIn(v.productCode,index)"><span class="icon-svg icon-cart">
										<svg viewBox="0 0 22 21">
											<use xlink:href="#icon-cart"></use>
										</svg>
									</span>カートに入れる</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="stock-notice">
						<ul>
							<li>○ 在庫あり</li>
							<li>□ 在庫確認中</li>
							<li>× SOLD OUT</li>
						</ul>
					</div>
					<div class="contents">
						<p v-html="description1"></p>
					</div>
					<div class="notice-box guide">
						<p>【在庫確認中】のときは…<br>未入金キャンセルが発生した際に、商品をご購入いただける場合があります。<br>※この場合「在庫確認中」から「在庫あり」に表示が変わります。</p>
						<p>【SOLD OUT】のときは…<br>申し訳ございません。品切れのため、商品のご用意ができません。</p>
					</div>
					<div class="notice-box caution">
						<p>【お買い物の際はご注意ください】<br>※複数商品を購入時は、出荷タイミングが最も遅い商品に合わせてまとめて発送いたします。<br>※同時に購入できないものをカートに入れた場合、カート確認画面に「別々にご注文ください」のご案内が表示されます。</p>
					</div>
				</div>
			</article>
			<a class="sw-Button canClick" @click="backList"><span class="icon-svg icon-left">
				<svg viewBox="0 0 8 12">
					<use xlink:href="#icon-chevron_left"></use>
				</svg>
			</span>戻る</a>
		</div>
	</span>
</template>

<style>
	.slide-item img {
		width: 100%;
	}
</style>

<script>
	import slick from 'vue-slick'
	export default{
		components:{
			slick,
		},
		data(){
			return{
				slickOptions:{
					slidesToShow: 1,
					arrows: true,
					dots: true,
					prevArrow: '<button type="button" class="slide-prev slick-arrow"><span class="icon-svg"><svg class="icon" viewBox="0 0 8 12"><use xlink:href="#icon-chevron_left"></use></svg></span></button>',
					nextArrow: '<button type="button" class="slide-next slick-arrow canClick"><span class="icon-svg"><svg class="icon" viewBox="0 0 8 12"><use xlink:href="#icon-chevron_right"></use></svg></span></button>',
					dotsClass: 'pagination',
				},
				pagenum:this.$store.getters.get_pnum,
				host:process.env.VUE_APP_IAM,
				cartDomain:process.env.VUE_APP_CARTURI,
				code:this.$route.params.hin,
				codeIndex:Number,
				cartUrl:'/v2/ShoppingCart.aspx?scode=ccrb&pcode=',
				rtnURL:'&rtn=',
				cartInUrl:'',
				pList:[],
				sList:[],
				title:'',
				price:'',
				description1:'',
				description2:'',
				isContent:true,
				isSP:false,
				variations:[],
				isSingleImg:false,
				isVariation:false,
				isStocks:false,
				qtyBox: [],
				qtySelect:1,
				qty: '&qty=',
				root: this.$store.getters.get_rootTransition,
				stockf : this.$store.getters.get_selectedstock,
				artistf : this.$store.getters.get_selectedartist,
				tourf : this.$store.getters.get_selectedtour,
				diskf : this.$store.getters.get_selecteddisk,
				otherf : this.$store.getters.get_selectedother,
			}
		},
		created(){
			scrollTo(0,0)
			this.getData()
		},
		mounted(){
			document.title = 'GOODS(販売商品) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
			document.querySelector("meta[property='og:title']").setAttribute('content','GOODS(販売商品) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]')
		},
		methods:{
			async getData(){
				try{
					const product = await this.$axios.get(this.$uri+'/product/'+this.code)
					const stocks = await this.$axios.get(this.$uri+'/productStock')
					this.pList = product.data
					this.sList = stocks.data
					this.setData()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			next() {
				this.$refs.slick.next();
			},
			prev() {
				this.$refs.slick.prev();
			},
			reInit() {
				this.$refs.slick.destroy();
				this.$nextTick(() => {
					this.$refs.slick.create();
				});
			},
			setData(){
				this.variations = this.pList.variations
				this.codeIndex = this.variations.findIndex((v)=> v.productCode == this.code)
				this.title = this.pList.title
				this.price = this.pList.variations[this.codeIndex].price
				this.description1 = this.pList.descriptions[0]
				this.description2 = this.pList.descriptions[1]
				if(this.description2 == ""){
					this.isContent = false
				}
				if(this.variations.length > 1){
					this.isVariation = true
				}else{
					this.isVariation = false
				}

				if(this.pList.images.length == 0 || this.pList.images.length == 1){
					this.isSingleImg = true
				}
				this.reInit()

				this.setQty(this.variations.length)

				if(this.root == 'product' || this.root == ''){
					this.rtnURL = this.rtnURL+encodeURIComponent(this.host+'/goods/'+this.pagenum+'?stock='+this.stockf+'&artist='+this.artistf+'&tour='+this.tourf+'&disk='+this.diskf+'&other='+this.otherf)
				}else{
					this.rtnURL = this.rtnURL+encodeURIComponent(this.host+'/special/'+this.root+'/'+this.pagenum)
				}
			},
			imageSet(image_obj){
				if(image_obj[0] === void 0){
					return '/assets/images/nowprinting3.gif'
				}else{
					return image_obj[0].url
				}
			},
			judgeStatus(hin){
				let stock = this.sList.find((v)=>v.productCode == hin)
				if(stock.available){
					return false
				}else{
					return true
				}
			},
			setQty(length){
				for(var i=0; i<length; i++){
					this.qtyBox[i] = 1
				}
			},
			qtyLimit(v){
				if(v.quantityLimited){
					if(v.quantityLimit == 0){
						return 1
					}else{
						return v.quantityLimit
					}
				}else{
					return 99
				}
			},
			chgCount(to, index, v){
				if(to=='up'){
					if(this.qtyBox[index] != this.qtyLimit(v)){
						this.qtyBox.splice(index,1,this.qtyBox[index]+1)
					}
				}else{
					if(this.qtyBox[index] != 1){
						this.qtyBox.splice(index,1,this.qtyBox[index]-1)
					}
				}
			},
			cartIn(code,index){
				this.cartInURL = this.cartDomain + this.cartUrl + code + this.qty + this.qtyBox[index] + this.rtnURL
				window.location.href=this.cartInURL
			},
			backList(){
				if(this.root == 'product' || this.root == ''){
					this.tagMove({path:'/goods/'+this.pagenum+'?stock='+this.stockf+'&artist='+this.artistf+'&tour='+this.tourf+'&disk='+this.diskf+'&other='+this.otherf, params:{'num':this.pagenum}})
				}else{
					this.tagMove({path:'/special/'+this.root+'/'+this.pagenum, params:{'num':this.pagenum}})
				}
			},
			tagMove(to){
				// this.$gtag.query('config', '', {'page_path': to.path});
				this.$router.push({'path':to.path, 'params':to.params})
			},
			chkStock(code){
				let av = []
				av.push(this.sList.find((n)=> n.productCode == code))
				if(av.filter((v)=>v.available==true).length>0){
					this.isStocks = false
					return '〇'
				}else{
					this.isStocks = true
					return '×'
				}
			},
			priceFilter(value){
				if (!value) return ''
				return value.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,')
			},
		},
	}

</script>
