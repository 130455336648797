<template>
	<span>
		<div class="st-Page_Header">
			<h1 class="title"><span class="en">NEWS</span><span class="ja">お知らせ</span></h1>
		</div>
		<div class="st-Page_Body">
			<div class="st-News_List">
				<ul>
					<li v-for="news in nList.slice(minSlice,minSlice+perPage)" :key="news.index"><a class="canClick" @click="toArticle(news.id)">
						<article>
							<div class="news-meta">
								<p class="date">
									<span class="icon-svg">
										<svg viewBox="0 0 48 55">
											<use xlink:href="#icon-rabbit"></use>
										</svg>
									</span>{{dateFilter(news.date)}}
								</p><span :class="setCategory('0', news.categories)">{{setCategory('1', news.categories)}}</span>
							</div>
							<p class="news-title">{{news.title}}</p>
						</article>
					</a></li>
				</ul>
			</div>
			<div class="st-Pagination" :hidden="!isVisible">
				<ul>
					<li :class="isFirst ? 'none':''" class="prev" @click="preClick()"><a class="canClick"><span class="icon-svg">
						<svg viewBox="0 0 8 12">
							<use xlink:href="#icon-chevron_left"></use>
						</svg>
					</span></a></li>
					<li v-if="(lastPage>5)&&(curPage>4)" class="page"><span>..</span></li>
					<template v-for="page of lastPage">
						<li :class="activeNum(page)" v-if="page>(Number(curPage)-4) && page<(Number(curPage)+4)" :key="page.index" @click="pageClick(page)" class="page"><a class="canClick">{{page}}</a></li>
					</template>
					<li v-if="(lastPage>5)&&(curPage<(lastPage-3))" class="page"><span>..</span></li>
					<li :class="isLast ? 'none':''" @click="nextClick()" class="next"><a class="canClick"><span class="icon-svg">
						<svg viewBox="0 0 8 12">
							<use xlink:href="#icon-chevron_right"></use>
						</svg>
					</span></a></li>
				</ul>
			</div>
		</div>
	</span>
</template>

<script>
	export default{
		data(){
			return{
				nList:[],
				perPage:2,
				curPage:Number,
				minSlice:Number,
				num:Number,
				lastPage:Number,
				fadeapp:true,
				isVisible:true,
				isFirst:false,
				isLast:false,
			}
		},
		beforeCreate(){
			this.$store.commit('PAGE_MOVE','1')
		},
		created(){
			scrollTo(0,0)
			this.getData()
		},
		mounted(){
			document.title = 'NEWS(お知らせ) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
			document.querySelector("meta[property='og:title']").setAttribute('content','NEWS(お知らせ) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]')
		},
		methods:{
			async getData(){
				try{
					const news = await this.$axios.get(this.$uri+'/news')
					this.nList = news.data.news
					this.getPageCount()
					this.setPaging()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			setCategory(type, category){
				if(category.length < 1) {
					return ''
				} else {
					if(type == '0') {
						return 'tag ' + category[0].name
					} else if (type == '1') {
						return category[0].displayName
					}
				}
			},
			setPaging(){
				this.num = this.$route.params['num']
				if(isNaN(this.num)){
					this.num = this.$store.getters.get_pnum
				}
				if(this.num <= this.lastPage){
					this.$store.commit('PAGE_MOVE',this.num)
					this.minSlice = (this.num-1)*this.perPage
					this.curPage = this.num;
				}else{
					this.minSlice = 0;
					this.curPage = 1;
					this.$store.commit('PAGE_MOVE',Number(this.curPage))
				}
			},
			toArticle(id){
				this.$router.push('/news/detail/'+id)
			},
			tagMove(to){
				// this.$gtag.query('config', '', {'page_path': to.path});
				this.$router.push({'path':to.path, 'params':to.params})
			},
			pageClick(page){
				this.fadeOut()
				scrollTo(0,0)
				if(page<this.curPage){
					this.curPage=this.curPage-1
				}else if(page>this.curPage){
					this.curPage=this.curPage+1
				}
				this.minSlice = (page-1)*this.perPage;
				this.curPage = Number(page);
				this.$store.commit('PAGE_MOVE',this.curPage)
				this.tagMove({path:'/news/'+page, params:{}})
				setTimeout(this.fadeIn,400)
			},
			preClick(){
				if(!(Number(this.curPage)<=1)){
					this.pageClick(Number(Number(this.curPage))-1);
				}
			},
			nextClick(){
				if(Number(this.curPage)!==this.lastPage){
					this.pageClick(Number(Number(this.curPage))+1);
				}
			},
			activeNum(page){
				if (Number(this.curPage)==1) {
					this.isFirst = true
					this.isLast = false
				}else if(Number(this.curPage)==this.lastPage){
					this.isLast = true
					this.isFirst = false
				}else{
					this.isFirst = false
					this.isLast = false
				}
				if(page==Number(this.curPage)){
					return 'current';
				}
			},
			getPageCount(){
				this.lastPage = Math.ceil(this.nList.length / this.perPage)
				if(this.lastPage<=1){
					this.isVisible=false;
				}else{
					this.isVisible=true;
				}
			},
			chkVisiblePage(num){
				if(this.lastPage>5){
					if(this.curPage<=3){
						if(num<=5){
							return true
						}else{
							return false
						}
					}else if(this.curPage>=this.lastPage-5){
						if(num>this.lastPage-5){
							return true
						}else if(num>=(this.curPage-2) && num<=(this.curPage+2)){
							return true
						}else{
							return false
						}
					}
				}else{
					return true
				}
			},
			fadeIn(){
				this.fadeapp = true
			},
			fadeOut(){
				this.fadeapp = false
			},
			dateFilter(date){
				return date.replace(/-/g,'.')
			},
		}
	}

</script>