<template>
	<span>
		<loading></loading>
		<div class="index-KeyVisual">
			<div class="container">
				<img class="rendering member maitake" src="/assets/images/common/member/img_maitake.png" alt="まいたけ"><img class="rendering member fenikuro" src="/assets/images/common/member/img_fenikuro.png" alt="ふぇにくろ"><img class="rendering member pokekamen" src="/assets/images/common/member/img_pokekamen.png" alt="ポケカメン"><img class="rendering member sakurakun" src="/assets/images/common/member/img_sakurakun.png" alt="さくらくん。"><img class="rendering member kanichan" src="/assets/images/common/member/img_kanichan.png" alt="かにちゃん"><img class="rendering member yupekun" src="/assets/images/common/member/img_yupekun.png" alt="ゆぺくん☆★"><img class="rendering logo" src="/assets/images/common/index/logo_kv.png" alt="ちょこらびえんすすとあ">
			</div>
		</div>
		<div class="index-Guide">
			<div class="container">
				<ul>
					<li><a class="canClick" @click="toGo('/static/guide')"><img class="w-100 rendering" src="/assets/images/common/index/bnr_guide.png" alt="お買い物ガイド"></a></li>
					<li><a class="canClick" @click="toGo('/goods')"><img class="w-100 rendering" src="/assets/images/common/index/bnr_goods.png" alt="商品一覧"></a></li>
				</ul>
			</div>
		</div>
		<div class="index-Contents">
			<div class="index-Banner">
				<slick class="mainslider" ref="slick" :options="slickOptions">
					<a v-for="img in sList" :key="img.index" :href="img.linkUrl">
						<img class="w-100 rendering" :src="img.imageUrl" :alt="img.alternateText">
					</a>
				</slick>
			</div>
			<div class="index-News">
				<div class="st-News_List">
					<ul>
						<li v-for="news in nList" :key="news.index">
							<a class="canClick" @click="toGo('/news/detail/'+news.id)">
								<article>
									<div class="news-meta">
										<p class="date"><span class="icon-svg">
											<svg viewBox="0 0 48 55">
												<use xlink:href="#icon-rabbit"></use>
											</svg>
										</span>{{dateFilter(news.date)}}</p><span class="tag" :class="news.categories[0].name">{{news.categories[0].displayName}}</span>
									</div>
									<p class="news-title">{{news.title}}</p>
								</article>
							</a>
						</li>
					</ul>
				</div>
				<a class="sw-Button canClick" @click="toGo('/news')">ニュース一覧へ
					<span class="icon-svg icon-right">
						<svg viewBox="0 0 8 12">
							<use xlink:href="#icon-chevron_right"></use>
						</svg>
					</span>
				</a>
			</div>
		</div>
	</span>
</template>

<script>
	import loading from './loading.vue'
	import slick from 'vue-slick'
	export default{
		components:{
			loading,
			slick,
		},
		data(){
			return{
				slickOptions:{
					slidesToShow: 2,
					slidesToScroll: 2,
					speed: 800,
					autoplay: true,
					autoplaySpeed: 3e3,
					arrows: true,
					prevArrow: '<button class="slide-prev slick-arrow canClick"><span class="icon-svg"><svg class="icon" viewBox="0 0 8 12"><use xlink:href="#icon-chevron_left"></use></svg></span></button>',
					nextArrow: '<button class="slide-next slick-arrow canClick"><span class="icon-svg"><svg class="icon" viewBox="0 0 8 12"><use xlink:href="#icon-chevron_right"></use></svg></span>/button>',
					dots: true,
					dotsClass: 'pagination',
					responsive:[{breakpoint:769,settings:{slidesToShow:1,slidesToScroll:1}}]
				},
				nList:[],
				sList:[],
			}
		},
		created(){
			this.getData()
		},
		mounted(){
			document.title = 'ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
			document.querySelector("meta[property='og:title']").setAttribute('content','ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]')
		},
		methods:{
			async getData(){
				try{
					const news = await this.$axios.get(this.$uri+'/news?$count=5')
					const sImg = await this.$axios.get(this.$uri+'/image?key=slider-image')

					this.nList = news.data.news
					this.sList = sImg.data.images

					this.reInit()

				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			toGo(to){
				this.$router.push(to)
			},
			dateFilter(date){
				return date.replace(/-/g,'.')
			},
			next() {
				this.$refs.slick.next()
			},
			prev() {
				this.$refs.slick.prev()
			},
			reInit() {
				this.$refs.slick.destroy()
				this.$nextTick(() => {
					this.$refs.slick.create()
				});
			},
		}
	}
</script>