<template>
	<span>
		<div class="st-Page_Header">
			<p class="title"><span class="en">NEWS</span><span class="ja">お知らせ</span></p>
		</div>
		<div class="st-Page_Body">
			<article class="news-Article">
				<div class="news-header">
					<div class="meta">
						<p class="date">
							<span class="icon-svg">
								<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
								</svg>
							</span>
							{{date}}
						</p>
						<span class="tag" :class="categoryName">{{categoryDisp}}</span>
					</div>
					<h1 class="title">{{title}}</h1>
				</div>
				<div class="news-body">
					<div class="st-Contents" v-html="content">
					</div>
				</div>
			</article>
			<a class="sw-Button canClick" @click="toNews">
				<span class="icon-svg icon-left">
					<svg viewBox="0 0 8 12">
						<use xlink:href="#icon-chevron_left"></use>
					</svg>
				</span>戻る
			</a>
		</div>
	</span>
</template>

<script>
	export default{
		data(){
			return{
				id: this.$route.params['id'],
				pagenum:this.$store.getters.get_pnum,
				title:'',
				date:'',
				categoryDisp:'',
				categoryName:'',
				content:'',
			}
		},
		created(){
			scrollTo(0,0)
			this.getData()
		},
		mounted(){
			document.title = 'NEWS(お知らせ) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
			document.querySelector("meta[property='og:title']").setAttribute('content','NEWS(お知らせ) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]')
		},
		methods:{
			async getData(){
				try{
					const article = await this.$axios.get(this.$uri+'/news/'+this.id)

					this.title = article.data.title
					this.categoryDisp = article.data.categories[0].displayName
					this.categoryName = article.data.categories[0].name
					this.date = this.dateFilter(article.data.date)
					this.content = article.data.body

				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			toNews(){
				// this.$gtag.query('config', '', {'page_path': to.path});
				this.$router.push({path:'/news/'+this.pagenum, params:{'num':this.pagenum}})
			},
			dateFilter(date){
				return date.replace(/-/g,'.')
			},
		},
	}
</script>