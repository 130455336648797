<template>
	<span v-html="contents"></span>
</template>

<script>
	export default{
		data(){
			return{
				host:process.env.VUE_APP_IAM,
				s3:process.env.VUE_APP_S3,
				key:this.$route.params.key,
				contents:'',
				date:new Date().getTime(),
				pageTitle:'',
			}
		},
		created(){
			scrollTo(0,0)
			this.getData()
		},
		mounted(){
			document.querySelector("meta[property='og:title']").setAttribute('content',this.pageTitle)
		},
		methods:{
			async getData(){
				try{
					const page = await this.$axios.get(this.$uri+'/page/'+this.key)
					this.pageTitle = page.data.title
					document.title = this.pageTitle
					this.contents = page.data.body
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			backTop(){
				// this.$gtag.query('config', '', {'page_path': '/'});
				this.$router.push({'path':'/', 'params':''})
			},
			setCss(){
				let cssBlock = document.createElement('link')

				cssBlock.setAttribute('rel','stylesheet')
				cssBlock.setAttribute('href',this.s3+'/customize.css?v='+this.date)
				document.head.appendChild(cssBlock)
			},
			setJS(){
				let scriptBlock = document.createElement('script')
				
				scriptBlock.setAttribute('src',this.s3+'/customize.js?v='+this.date)
				document.body.appendChild(scriptBlock)
			},
		}
	}
</script>