import Vue from 'vue'
import VueRouter from 'vue-router'
// import gtag from 'vue-gtag'
import top from '../components/top.vue'
import special from '../components/special.vue'
import goods from '../components/goods.vue'
import detail from '../components/goodsDetail.vue'
import article from '../components/article.vue'
import news from '../components/news.vue'
import lp from '../components/lp.vue'
import member from '../components/member.vue'
import notfound from '../components/404.vue'

Vue.use(VueRouter)
// Vue.use(gtag, {
//   config:{ id:'' },
// },router)

const routes = [
  {
    path: '/',
    name: 'top',
    component: top
  },
  {
    path: '/special/:name',
    component: special,
    children: [
      {
        path: '/special/:name/:num',
      },
    ]
  },
  {
    path: '/member',
    component: member,
  },
  {
    path: '/goods',
    name: 'goods',
    component: goods,
    children: [
      {
        path: '/goods/:num',
      },
    ]
  },
  {
    path: '/goods/detail/:hin',
    component: detail,
  },
  {
    path:'/news',
    component: news,
    children:[
      {
        path: '/news/:num',
      },
    ]
  },
  {
    path: '/news/detail/:id',
    component: article,
  },
  {
    path: '/static/:key',
    component: lp,
  },
  {
    path: '/error/404',
    component: notfound,
  },
  {
    path: '*',
    redirect: '/error/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  router['referrer'] = from;
  next();
})


export default router
