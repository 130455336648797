import Vue from 'vue'
import router from './router'
import store from './store'
import {sync} from 'vuex-router-sync'
import axios from 'axios'
import vueAxios from 'vue-axios'
import jQuery from 'jquery'
import slick from 'vue-slick'
import gtag from 'vue-gtag'

import header from './components/header.vue'
import footer from './components/footer.vue'
import index from './views/index.vue'
import top from './components/top.vue'
import special from './components/special.vue'
import goods from './components/goods.vue'
import detail from './components/goodsDetail.vue'
import news from './components/news.vue'
import article from './components/article.vue'
import lp from './components/lp.vue'
import notfound from './components/404.vue'


const URL = process.env.VUE_APP_CLIENTAPI+'/ccrb'
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$uri = URL
// Vue.prototype.$slick = slick
Vue.use(vueAxios,axios,slick)
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')
sync(store,router)

Vue.use(gtag, {
  config:{ id: process.env.VUE_APP_GA },
},router)

new Vue({
  header,
  router,
  store,
  render: h => h(header)
}).$mount('#header')

new Vue({
  footer,
  router,
  store,
  render: h => h(footer)
}).$mount('#footer')

new Vue({
  router,
  store,
  top,
  goods,
  special,
  detail,
  news,
  article,
  lp,
  notfound,
  render: h =>h(index)
}).$mount("#index")