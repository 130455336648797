<template>
	<span>
		<div class="st-Page_Header">
			<h1 class="title"><span class="en">MEMBER</span><span class="ja">メンバー</span></h1>
		</div>
		<div class="st-Page_Body">
			<div class="member-list">
				<ul>
					<li><a href="#" data-modal="member" data-member="pokekamen"><img class="w-100 rendering" src="/assets/images/common/member/img_thumb_pokekamen.png" alt="ポケカメン"></a></li>
					<li><a href="#" data-modal="member" data-member="maitake"><img class="w-100 rendering" src="/assets/images/common/member/img_thumb_maitake.png" alt="まいたけ"></a></li>
					<li><a href="#" data-modal="member" data-member="fenikuro"><img class="w-100 rendering" src="/assets/images/common/member/img_thumb_fenikuro.png" alt="ふぇにくろ"></a></li>
					<li><a href="#" data-modal="member" data-member="kanichan"><img class="w-100 rendering" src="/assets/images/common/member/img_thumb_kanichan.png" alt="かにちゃん"></a></li>
					<li><a href="#" data-modal="member" data-member="sakurakun"><img class="w-100 rendering" src="/assets/images/common/member/img_thumb_sakurakun.png" alt="さくらくん。"></a></li>
					<li><a href="#" data-modal="member" data-member="yupekun"><img class="w-100 rendering" src="/assets/images/common/member/img_thumb_yupekun.png" alt="ゆぺくん☆★"></a></li>
				</ul>
			</div>
		</div>
		<div class="member-Modal">
			<div class="wrapper">
				<div class="container pokekamen">
					<button class="close" type="button"></button>
					<div class="contents">
						<div class="wholebody"><img class="rendering" src="/assets/images/common/member/img_pokekamen.png"></div>
						<div class="profile">
							<div class="heading">
								<h2>
									<span class="name">
										<img src="/assets/images/common/member/txt_pokekamen.svg" alt="ポケカメン">
									</span>
									<span class="star star-left">
										<span class="icon-svg">
											<svg viewBox="0 0 51 50">
												<use xlink:href="#icon-star"></use>
											</svg>
										</span>
										<span class="icon-svg">
											<svg viewBox="0 0 51 50">
												<use xlink:href="#icon-star"></use>
											</svg></span><span class="icon-svg">
											<svg viewBox="0 0 51 50">
												<use xlink:href="#icon-star"></use>
											</svg></span></span><span class="star star-right"><span class="icon-svg">
											<svg viewBox="0 0 51 50">
												<use xlink:href="#icon-star"></use>
											</svg></span><span class="icon-svg">
											<svg viewBox="0 0 51 50">
												<use xlink:href="#icon-star"></use>
											</svg></span><span class="icon-svg">
											<svg viewBox="0 0 51 50">
												<use xlink:href="#icon-star"></use>
											</svg>
										</span>
									</span>
								</h2>
								<p>ちょこらびのリーダーまとめ役のイケボの青色担当ポケカメンです！</p>
							</div>
							<div class="detail">
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>誕生日</dt>
									<dd>11月15日</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>身長</dt>
									<dd>180cm</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>血液型</dt>
									<dd>B型</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>星座</dt>
									<dd>さそり座</dd>
								</dl>
							</div>
							<div class="message">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>リスナーに一言</h3>
								<p>夢は終わらねぇ！</p>
								</div>
								<div class="sns">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>メンバーSNS</h3>
								<ul>
								<li><a class="youtube" href="https://www.youtube.com/channel/UChVJyKoxVGt4KAqQodRlNBQ" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_youtube.svg" alt="YouTube"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_youtube.svg" alt="YouTube"></span></a></li>
								<li><a class="twitter" href="https://twitter.com/GC5R5OGIKgV0yvz" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_twitter.svg" alt="Twitter"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_twitter.svg" alt="Twitter"></span></a></li>
								<li><a class="instagram" href="https://www.instagram.com/pokestgram/" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_instagram.svg" alt="Instagram"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_instagram.svg" alt="Instagram"></span></a></li>
								<li><a class="tiktok" href="https://www.tiktok.com/@pokekamenbusu" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_tiktok.svg" alt="Tik Tok"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_tiktok.svg" alt="Tik Tok"></span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="container maitake">
					<button class="close" type="button"></button>
					<div class="contents">
						<div class="wholebody"><img class="rendering" src="/assets/images/common/member/img_maitake.png"></div>
						<div class="profile">
							<div class="heading">
								<h2><span class="name"><img src="/assets/images/common/member/txt_maitake.svg" alt="まいたけ"></span><span class="star star-left"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span><span class="star star-right"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span></h2>
								<p>赤色担当まいたけです！ぶっちゃけ隠してましたけどメンバーの中で実は一番魅力的です。隠しててすみません！！</p>
							</div>
							<div class="detail">
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>誕生日</dt>
									<dd>1月12日</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>身長</dt>
									<dd>172cm</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>血液型</dt>
									<dd>B型</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>星座</dt>
									<dd>やぎ座</dd>
								</dl>
							</div>
							<div class="message">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>リスナーに一言</h3>
								<p>知ってる？両手を伸ばした長さと身長は同じなんだよ？</p>
							</div>
							<div class="sns">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>メンバーSNS</h3>
								<ul>
									<li><a class="youtube" href="https://www.youtube.com/channel/UCgIHvyZWE6Ny7pfRpya86Yw" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_youtube.svg" alt="YouTube"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_youtube.svg" alt="YouTube"></span></a></li>
									<li><a class="twitter" href="https://twitter.com/_kinotake_" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_twitter.svg" alt="Twitter"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_twitter.svg" alt="Twitter"></span></a></li>
									<li><a class="instagram" href="https://www.instagram.com/m_kino0112/" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_instagram.svg" alt="Instagram"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_instagram.svg" alt="Instagram"></span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="container fenikuro">
					<button class="close" type="button"></button>
					<div class="contents">
						<div class="wholebody"><img class="rendering" src="/assets/images/common/member/img_fenikuro.png"></div>
						<div class="profile">
							<div class="heading">
								<h2><span class="name"><img src="/assets/images/common/member/txt_fenikuro.svg" alt="ふぇにくろ"></span><span class="star star-left"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span><span class="star star-right"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span></h2>
								<p>ちょこらびの癒し＆スーパー可愛い＆フツボ、仔犬の上目遣いオレンジ色担当のふぇにくろです！</p>
							</div>
							<div class="detail">
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>誕生日</dt>
									<dd>7月31日</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>身長</dt>
									<dd>183cm</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>血液型</dt>
									<dd>BかOか未だに知らない</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>星座</dt>
									<dd>しし座</dd>
								</dl>
							</div>
							<div class="message">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>リスナーに一言</h3>
								<p>生きててえらい。俺が君のモチベになるからね。</p>
							</div>
							<div class="sns">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>メンバーSNS</h3>
								<ul>
									<li><a class="youtube" href="https://www.youtube.com/channel/UCUfE1UwnsFI4hASAPcJ-2pw" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_youtube.svg" alt="YouTube"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_youtube.svg" alt="YouTube"></span></a></li>
									<li><a class="twitter" href="https://mobile.twitter.com/fenikuro_fn_" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_twitter.svg" alt="Twitter"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_twitter.svg" alt="Twitter"></span></a></li>
									<li><a class="instagram" href="https://instagram.com/fenikuro_" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_instagram.svg" alt="Instagram"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_instagram.svg" alt="Instagram"></span></a></li>
									<li><a class="tiktok" href="https://www.tiktok.com/@fenikuro_" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_tiktok.svg" alt="Tik Tok"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_tiktok.svg" alt="Tik Tok"></span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="container kanichan">
					<button class="close" type="button"></button>
					<div class="contents">
						<div class="wholebody"><img class="rendering" src="/assets/images/common/member/img_kanichan.png"></div>
						<div class="profile">
							<div class="heading">
								<h2><span class="name"><img src="/assets/images/common/member/txt_kanichan.svg" alt="かにちゃん"></span><span class="star star-left"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span><span class="star star-right"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span></h2>
								<p>ちょこらびの黄色担当かにちゃんです！こっちみんな照れるだろばか。</p>
							</div>
							<div class="detail">
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>誕生日</dt>
									<dd>12月28日</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>身長</dt>
									<dd>170ちょい</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>血液型</dt>
									<dd>A型</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>星座</dt>
									<dd>やぎ座</dd>
								</dl>
							</div>
							<div class="message">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>リスナーに一言</h3>
								<p>君の隣は僕だけだよ！！</p>
							</div>
							<div class="sns">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>メンバーSNS</h3>
								<ul>
									<li><a class="youtube" href="https://www.youtube.com/channel/UC6Tv4hvzGHvuqHIOoibCI8A" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_youtube.svg" alt="YouTube"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_youtube.svg" alt="YouTube"></span></a></li>
									<li><a class="twitter" href="https://twitter.com/kanichan_2255" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_twitter.svg" alt="Twitter"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_twitter.svg" alt="Twitter"></span></a></li>
									<li><a class="instagram" href="https://www.instagram.com/kanichan_2525/" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_instagram.svg" alt="Instagram"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_instagram.svg" alt="Instagram"></span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="container sakurakun">
					<button class="close" type="button"></button>
					<div class="contents">
						<div class="wholebody"><img class="rendering" src="/assets/images/common/member/img_sakurakun.png"></div>
						<div class="profile">
							<div class="heading">
								<h2><span class="name"><img src="/assets/images/common/member/txt_sakurakun.svg" alt="さくらくん。"></span><span class="star star-left"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span><span class="star star-right"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span></h2>
								<p>ちょこらびのピンク担当さくらくん。です！絶対結婚しような！日々感謝。</p>
							</div>
							<div class="detail">
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>誕生日</dt>
									<dd>1月5日</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>身長</dt>
									<dd>172cm（仮）</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>血液型</dt>
									<dd>B型</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>星座</dt>
									<dd>やぎ座</dd>
								</dl>
							</div>
							<div class="message">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>リスナーに一言</h3>
								<p>これからも一緒にいて！！！絶対絶対幸せにするから！！！</p>
								</div>
								<div class="sns">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>メンバーSNS</h3>
								<ul>
									<li><a class="youtube" href="https://www.youtube.com/channel/UCNK9sGGGJVxoLHQxuBp0bbA" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_youtube.svg" alt="YouTube"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_youtube.svg" alt="YouTube"></span></a></li>
									<li><a class="twitter" href="https://twitter.com/sakura_25nico" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_twitter.svg" alt="Twitter"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_twitter.svg" alt="Twitter"></span></a></li>
									<li><a class="instagram" href="https://www.instagram.com/sakura_25nico/" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_instagram.svg" alt="Instagram"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_instagram.svg" alt="Instagram"></span></a></li>
									<li><a class="tiktok" href="https://www.tiktok.com/@sakura_25nico" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_tiktok.svg" alt="Tik Tok"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_tiktok.svg" alt="Tik Tok"></span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="container yupekun">
					<button class="close" type="button"></button>
					<div class="contents">
						<div class="wholebody"><img class="rendering" src="/assets/images/common/member/img_yupekun.png"></div>
						<div class="profile">
							<div class="heading">
								<h2><span class="name"><img src="/assets/images/common/member/txt_yupekun.svg" alt="ゆぺくん☆★"></span><span class="star star-left"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span><span class="star star-right"><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span><span class="icon-svg">
								<svg viewBox="0 0 51 50">
								<use xlink:href="#icon-star"></use>
								</svg></span></span></h2>
								<p>ちょこらびのリーダー、イケボ、みんなの癒し、週末彼氏、紫色担当のゆぺくん☆★です！</p>
							</div>
							<div class="detail">
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>誕生日</dt>
									<dd>11月23日</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>身長</dt>
									<dd>180cm</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>血液型</dt>
									<dd>O型</dd>
								</dl>
								<dl>
									<dt><span class="icon-svg">
									<svg viewBox="0 0 48 55">
									<use xlink:href="#icon-rabbit"></use>
									</svg></span>星座</dt>
									<dd>射手座</dd>
								</dl>
							</div>
							<div class="message">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>リスナーに一言</h3>
								<p>僕と君で一緒に大きくなっていこうね。</p>
							</div>
							<div class="sns">
								<h3><span class="icon-svg">
								<svg viewBox="0 0 48 55">
								<use xlink:href="#icon-rabbit"></use>
								</svg></span>メンバーSNS</h3>
								<ul>
									<li><a class="youtube" href="https://www.youtube.com/channel/UCJB6Gh5pg8GuCt1TCXNAaVg" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_youtube.svg" alt="YouTube"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_youtube.svg" alt="YouTube"></span></a></li>
									<li><a class="twitter" href="https://twitter.com/yupe_1123" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_twitter.svg" alt="Twitter"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_twitter.svg" alt="Twitter"></span></a></li>
									<li><a class="instagram" href="https://www.instagram.com/yupe_1123/" target="_blank"><span class="icon"><img class="w-100" src="/assets/images/common/member/icon_instagram.svg" alt="Instagram"></span><span class="text"><img class="w-100" src="/assets/images/common/member/txt_instagram.svg" alt="Instagram"></span></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</span>
</template>

<script>
	export default{
		data(){
			return{
				category:'',
			}
		},
		created(){
			scrollTo(0,0)
			this.getData()
		},
		mounted(){
			document.title = 'MEMBER(メンバー) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
			document.querySelector("meta[property='og:title']").setAttribute('content','MEMBER(メンバー) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]')
		},
		methods:{
			async getData(){
				try{
					const api = await this.$axios.get(this.$uri+'/category')
					this.category = api.data
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
		},
	}
</script>