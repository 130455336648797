//import Vue from 'vue'

const state = {
	pagenum:1,
	stock:'0',
	artist:'0',
	tour:'0',
	disk:'0',
	other:'0',
	rootTransition:'product',
}

const mutations = {
	'PAGE_MOVE'(state, val){
		state.pagenum = val
	},
	'CHANGE_STOCK'(state, val){
		state.stock = val
	},
	'CHANGE_ARTIST'(state, val){
		state.artist = val
	},
	'CHANGE_TOUR'(state, val){
		state.tour = val
	},
	'CHANGE_DISK'(state, val){
		state.disk = val
	},
	'CHANGE_OTHER'(state, val){
		state.other = val
	},
	'CHANGE_ROOT'(state, val){
		state.rootTransition = val
	},
};

const actions = {
	set_page:({commit},val) => {
		commit('PAGE_MOVE',val)
	},
	select_stock:({commit},val) => {
		commit('CHANGE_STOCK',val)
	},
	select_artist:({commit},val) => {
		commit('CHANGE_ARTIST',val)
	},
	select_tour:({commit},val) => {
		commit('CHANGE_TOUR',val)
	},
	select_disk:({commit},val) => {
		commit('CHANGE_DISK',val)
	},
	select_other:({commit},val) => {
		commit('CHANGE_OTHER',val)
	},
	set_root:({commit},val) => {
		commit('CHANGE_ROOT', val)
	},
};

const getters = {
	get_pnum: state => {
		return state.pagenum
	},
	get_selectedstock: state => {
		return state.stock
	},
	get_selectedartist: state => {
		return state.artist
	},
	get_selectedtour: state => {
		return state.tour
	},
	get_selecteddisk: state => {
		return state.disk
	},
	get_selectedother: state => {
		return state.other
	},
	get_rootTransition: state => {
		return state.rootTransition
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
}