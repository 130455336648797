<template>
	<div class="cmn-Page_Body">
		<p>お探しのURLは存在しません。<br>削除されたか、<br class="sp_i">移動した可能性がございます。</p>
		<a class="sw-Button" href="/">ホームへ<span class="icon-svg icon-right">
			<svg viewBox="0 0 8 12">
				<use xlink:href="#icon-chevron_right"></use>
			</svg>
		</span></a>
	</div>
</template>

<script>
	export default{
		mounted(){
			document.title = 'お探しのURLは存在しません | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
		}
	}
</script>