<template>
  <router-view></router-view>
</template>

<script>
  export default{
    data(){
      return{
        path:this.$route.path,
        target:document.getElementsByClassName('st-Wrapper')[0]
      }
    },
    created(){
      this.chkPath()
    },
    methods:{
      chkPath(){
        let wrapperClass = this.target.classList
        if(wrapperClass.contains('sw-Bg_Yellow')){
          wrapperClass.remove('sw-Bg_Yellow')
        }else if(wrapperClass.contains('sw-Bg_Pink')){
          wrapperClass.remove('sw-Bg_Pink')          
        }

        if(this.path == '/'){
          wrapperClass.add('sw-Bg_Yellow')
          this.setCss('index',true,true)
          this.setJs('index',true,true)
        }else if(this.path.indexOf('/goods')!=-1 && this.path.indexOf('/detail')==-1){
          wrapperClass.add('sw-Bg_Pink')
          this.setCss('goods',false,true)
          this.setJs('common',true,true)
        }else if(this.path.indexOf('/goods')!=-1 && this.path.indexOf('/detail')!=-1){
          wrapperClass.add('sw-Bg_Pink')
          this.setCss('goods',true,true)
          this.setJs('goods',true,true)
        }else if(this.path.indexOf('/news')!=-1){
          wrapperClass.add('sw-Bg_Yellow')
          this.setCss('news',false,true)
          this.setJs('common',true,true)
        }else if(this.path.indexOf('/special')!=-1){
          wrapperClass.add('sw-Bg_Pink')
          this.setCss('special',false,true)
          this.setJs('common',true,true)
        }else if(this.path.indexOf('/member')!=-1){
          this.setCss('member',false,true)
          this.setJs('common',true,false)
          this.setJs('member',false,true)
        }else if(this.path.indexOf('/guide')!=-1){
          wrapperClass.add('sw-Bg_Yellow')
          this.setCss('guide',false,true)
          this.setJs('common',true,true)
        }else if(this.path.indexOf('/404')!=-1){
          wrapperClass.add('sw-Bg_Yellow')
          this.setCss('common',false,true)
          this.setJs('common',true,true)
        }

      },
      setCss(dir,lib,style){
        let libBlock = document.createElement('link')
        libBlock.rel = 'stylesheet'
        let styleBlock = document.createElement('link')
        styleBlock.rel = 'stylesheet'
        if(lib){
          libBlock.href = '/assets/css/'+dir+'/lib.css'
          document.head.appendChild(libBlock)
        }else{
          libBlock.href = ''
          document.head.appendChild(libBlock)
        }

        if(style){
          styleBlock.href = '/assets/css/'+dir+'/style.css'
          document.head.appendChild(styleBlock)
        }else{
          styleBlock.href = ''
          document.head.appendChild(styleBlock)
        }
      },
      setJs(dir,lib,app){
        let libBlock = document.createElement('script')        
        let appBlock = document.createElement('script')

        if(lib){
          libBlock.src = '/assets/js/'+dir+'/lib.js'
          document.body.appendChild(libBlock)
        }else{
          libBlock.src = ''
          document.body.appendChild(libBlock)
        }

        if(app){
          appBlock.src = '/assets/js/'+dir+'/app.bundle.js'
          document.body.appendChild(appBlock)
        }else{
          appBlock.src = ''
          document.body.appendChild(appBlock)
        }

      },
    },
    watch:{
      '$route' :function(to){
        if(to.path == '/'){
          window.location.reload()
        }
        this.path = to.path
        this.chkPath()
      }
    }
  }
</script>