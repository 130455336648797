<template>
	<span>
		<div class="st-Page_Header">
			<h1 class="title"><span class="en">GOODS</span><span class="ja">販売商品</span></h1>
		</div>
		<div class="st-Page_Body">
			<div class="goods-Filter">
				<ul>
					<li>
						<div class="select"><span class="icon-svg">
							<svg viewBox="0 0 10 6">
								<use xlink:href="#icon-caret_down"></use>
							</svg></span>
							<select v-model="stock" @change="pageMove('stock')">
								<option value="0">販売終了商品も含む</option>
								<option value="1">販売中の商品</option>
							</select>
						</div>
					</li>
					<li>
						<div class="select"><span class="icon-svg">
							<svg viewBox="0 0 10 6">
								<use xlink:href="#icon-caret_down"></use>
							</svg></span>
							<select v-model="artist" @change="pageMove('artist')">
								<option value="0">メンバー名から選ぶ</option>
								<option v-for="art in aCategoryList" :key="art.index" :value="art.name">{{art.displayName}}</option>
							</select>
						</div>
					</li>
					<li>
						<div class="select"><span class="icon-svg">
							<svg viewBox="0 0 10 6">
								<use xlink:href="#icon-caret_down" @change="pageMove('tour')"></use>
							</svg></span>
							<select v-model="tour" @change="pageMove('tour')">
								<option value="0">ライブ名から選ぶ</option>
								<option v-for="tour in tCategoryList" :key="tour.index" :value="tour.name">{{tour.displayName}}</option>
							</select>
						</div>
					</li>
					<li>
						<div class="select"><span class="icon-svg">
							<svg viewBox="0 0 10 6">
								<use xlink:href="#icon-caret_down"></use>
							</svg></span>
							<select v-model="disk" @change="pageMove('disk')">
								<option value="0">CDから選ぶ</option>
								<option v-for="disk in dCategoryList" :key="disk.index" :value="disk.name">{{disk.displayName}}</option>
							</select>
						</div>
					</li>
					<li>
						<div class="select"><span class="icon-svg">
							<svg viewBox="0 0 10 6">
								<use xlink:href="#icon-caret_down"></use>
							</svg></span>
							<select v-model="other" @change="pageMove('other')">
								<option value="0">カテゴリから選ぶ</option>
								<option v-for="other in oCategoryList" :key="other.index" :value="other.name">{{other.displayName}}</option>
							</select>
						</div>
					</li>
				</ul>
			</div>
			<div class="st-Goods_List">
				<ul :class="fadeapp ? 'appear':'vanish'">
					<li v-for="products in displayList.slice(minSlice, minSlice+perPage)" :key="products.index">
						<a class="canClick" @click="tagMove({path:'/goods/detail/'+products.variations[0].productCode, params:{}})">
							<article>
								<div class="goods-figure">
									<div class="inner">
										<img class="rendering" :src="imageSet(products.images)" alt="">
									</div>
									<span class="new" v-if="chkNew(products.variations[0].releaseDate)"></span>
									<span class="soldout" v-if="chkStatus(products.variations)"></span>
								</div>
								<div class="goods-body">
									<p class="title">{{products.title}}</p>
									<p class="price">{{products.variations[0].price}}<span>円<span class="tax">（税込）</span></span></p>
									<div class="meta">
										<div :class="setCategory('0', products.categories)">{{setCategory('1', products.categories)}}</div>
									</div>
								</div>
							</article>
						</a>
					</li>
				</ul>
			</div>
			<div class="st-Pagination" :hidden="!isVisible">
				<ul>
					<li :class="isFirst ? 'none':''" class="prev" @click="preClick()"><a class="canClick"><span class="icon-svg">
						<svg viewBox="0 0 8 12">
							<use xlink:href="#icon-chevron_left"></use>
						</svg>
					</span></a></li>
					<li v-if="(lastPage>5)&&(curPage>4)" class="page"><span>..</span></li>
					<template v-for="page of lastPage">
						<li :class="activeNum(page)" v-if="page>(Number(curPage)-4) && page<(Number(curPage)+4)" :key="page.index" @click="pageClick(page)" class="page"><a class="canClick">{{page}}</a></li>
					</template>
					<li v-if="(lastPage>5)&&(curPage<(lastPage-3))" class="page"><span>..</span></li>
					<li :class="isLast ? 'none':''" @click="nextClick()" class="next"><a class="canClick"><span class="icon-svg">
						<svg viewBox="0 0 8 12">
							<use xlink:href="#icon-chevron_right"></use>
						</svg>
					</span></a></li>
				</ul>
			</div>
		</div>
	</span>
</template>

<script>
	export default{
		data(){
			return{
				host:process.env.VUE_APP_IAM,
				query:this.$route.query,
				path:this.$route.path,
				year:new Date().getFullYear(),
				month:String(new Date().getMonth()+1),
				day:String(new Date().getDate()),
				hour:new Date().getHours(),
				minutes:new Date().getMinutes(),
				seconds:new Date().getSeconds(),
				pList:[],
				sList:[],
				cList:[],
				displayList:[],
				aCategoryList:[],
				tCategoryList:[],
				dCategoryList:[],
				oCategoryList:[],
				stock:'',
				artist:'',
				tour:'',
				disk:'',
				other:'',
				category:this.$store.getters.get_category,
				perPage:20,
				curPage:Number,
				minSlice:Number,
				num:Number,
				lastPage:Number,
				fadeapp:true,
				isVisible:true,
				isFirst:false,
				isLast:false,
			}
		},
		beforeCreate(){
			this.$store.commit('PAGE_MOVE','1')
		},
		created(){
			scrollTo(0,0)
			this.getCategory()
			this.getData()
		},
		mounted(){
			document.title = 'GOODS(販売商品) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
			document.querySelector("meta[property='og:title']").setAttribute('content','GOODS(販売商品) | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]')
		},
		methods:{
			getCategory(){
				if (this.$route.params.num === void 0){
					this.curPage = this.$store.getters.get_pnum
					this.$store.commit('PAGE_MOVE',Number(this.curPage))
				}else if(this.$route.params.num !== void 0){
					this.curPage = this.$route.params.num
					this.$store.commit('PAGE_MOVE',Number(this.curPage))
				}
				//在庫表示セット
				if (this.$route.query.stock === void 0 || this.$route.query.stock == 'all' || this.$route.query.stock == '0') {
					this.stock = this.$store.getters.get_selectedstock
				}else if(this.$route.query.stock == 'exist' || this.$route.query.stock == '1'){
					this.stock = 1
					this.$store.commit('CHANGE_STOCK', this.stock)
				}
				//アーティストセット
				if (this.$route.query.artist === void 0 || this.$route.query.artist == 'all' || this.$route.query.artist == '0') {
					this.artist = this.$store.getters.get_selectedartist
				}else{
					this.artist = this.$route.query.artist
					this.$store.commit('CHANGE_ARTIST', this.artist)
				}
				//ツアーセット
				if (this.$route.query.tour === void 0 || this.$route.query.tour == 'all' || this.$route.query.tour == '0') {
					this.tour = this.$store.getters.get_selectedtour
				}else{
					this.tour = this.$route.query.tour
					this.$store.commit('CHANGE_TOUR', this.tour)
				}
				//円盤セット
				if (this.$route.query.disk === void 0 || this.$route.query.disk == 'all' || this.$route.query.disk == '0') {
					this.disk = this.$store.getters.get_selecteddisk
				}else{
					this.disk = this.$route.query.disk
					this.$store.commit('CHANGE_DISK', this.disk)
				}
				//その他セット
				if (this.$route.query.other === void 0 || this.$route.query.other == 'all' || this.$route.query.other == '0') {
					this.other = this.$store.getters.get_selectedother
				}else{
					this.other = this.$route.query.other
					this.$store.commit('CHANGE_OTHER', this.other)
				}
			},
			async getData(){
				try{
					const products = await this.$axios.get(this.$uri+'/product')
					const stocks = await this.$axios.get(this.$uri+'/productStock')
					const category = await this.$axios.get(this.$uri+'/category')
					this.cList = category.data.categoryGroups
					this.aCategoryList = this.cList.find((v)=> v.name == 'member').categories
					this.tCategoryList = this.cList.find((v)=> v.name == 'concert').categories
					this.dCategoryList = this.cList.find((v)=> v.name == 'cd').categories
					this.oCategoryList = this.cList.find((v)=> v.name == 'category').categories
					this.pList = products.data.products
					this.sList = stocks.data
					this.pList.sort((a,b)=>{
					if(a.variations[0].releaseDate > b.variations[0].releaseDate) return -1
						return 0
					})
					for(var i=0; i<this.pList.length; i++){
						this.displayList[i] = this.pList[i]
					}
					this.sortList()
					setTimeout(this.fadeIn,400)
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			sortList(){
				let dnum=0
				let sList=[]
				let aList=[]
				let tList=[]
				let dList=[]
				let oList=[]
				if(this.displayList.length != 0){
					this.displayList.splice(-this.displayList.length);
				}

				//在庫ソート
				if (this.stock == 1) {
					for (let i=0; i<this.pList.length; i++) {
						let p = this.chkStocks(this.pList[i].variations)
						if (p == true) {
							sList[dnum]=this.pList[i]
							// sList.splice(dnum,1,this.pList[i]);
							dnum++;
						}
					}
				}else if(this.stock == 0){
					for (let i=0; i<this.pList.length; i++) {
						sList[dnum]=this.pList[i]
						// sList.splice(dnum,1,this.pList[i]);
						dnum++;
					}
				}

				//アーティストソート
				if(this.artist == 0){
					for(let i=0; i<sList.length; i++){
						aList[i]=sList[i]
					}
				}else{
					dnum=0
					for (let i=0; i<sList.length; i++) {
						let p = sList[i].categories
						for(let k=0; k<p.length; k++){
							let q = p[k].name
							if(this.artist == q){
								aList[dnum]=sList[i]
								dnum++
							}
						}
					}
				}

				//イベントソート
				if(this.tour == 0){
					for(let i=0; i<aList.length; i++){
						tList[i]=aList[i]
					}
				}else{
					dnum=0
					for (let i=0; i<aList.length; i++) {
						let p = aList[i].categories
						for(let k=0; k<p.length; k++){
							let q = p[k].name
							if(this.tour == q){
								tList[dnum]=aList[i]
								dnum++
							}
						}
					}
				}

				//円盤ソート
				if(this.disk == 0){
					for(let i=0; i<tList.length; i++){
						dList[i]=tList[i]
					}
				}else{
					dnum=0
					for (let i=0; i<tList.length; i++) {
						let p = tList[i].categories
						for(let k=0; k<p.length; k++){
							let q = p[k].name
							if(this.disk == q){
								dList[dnum]=tList[i]
								dnum++
							}
						}
					}
				}

				//その他ソート
				if(this.other == 0){
					for(let i=0; i<dList.length; i++){
						oList[i]=dList[i]
					}
				}else{
					dnum=0
					for (let i=0; i<dList.length; i++) {
						let p = dList[i].categories
						for(let k=0; k<p.length; k++){
							let q = p[k].name
							if(this.other == q){
								oList[dnum]=dList[i]
								dnum++
							}
						}
					}
				}
				for(let i=0; i<oList.length; i++){
					this.displayList.splice(i,1,oList[i]);
				}

				this.getPageCount()
				this.setPaging()
			},
			chkStocks(variations){
				let variationCodes = []
				let stocks = []
				let tCount = []
				for(var v of variations){
					variationCodes.push(v.productCode)
				}
				for(var c of variationCodes){
					stocks.push(this.sList.find((v)=> v.productCode == c))
				}
				tCount = stocks.find((v)=> v.available == true)
				if(tCount == void 0){
					return false
				}else{
					return true
				}
			},
			pageMove(status){
				this.fadeOut()
				setTimeout( () => {
					this.curPage = Number(1)
					let to = '/goods/'+Number(this.curPage)+'?stock='
					this.$store.commit('CHANGE_STOCK', this.stock)
					if(this.stock == 1){
						to = to+'exist'
					}else{
						to = to+'all'
					}
					if(status == 'artist'){
						this.$store.commit('CHANGE_ARTIST', this.artist)
					}else if(status == 'tour'){
						this.$store.commit('CHANGE_TOUR', this.tour)
					}else if(status == 'disk'){
						this.$store.commit('CHANGE_DISK', this.disk)
					}else if(status == 'other'){
						this.$store.commit('CHANGE_OTHER', this.other)
					}
					if(this.artist != 0){
						to = to+'&artist='+this.artist
					}
					if(this.tour != 0){
						to = to+'&tour='+this.tour
					}
					if(this.disk != 0){
						to = to+'&disk='+this.disk
					}
					if(this.other != 0){
						to = to+'&other='+this.other
					}
					this.tagMove({path:to, params:{}})
					this.sortList()
					setTimeout(this.fadeIn,400)
				},500);
			},
			getPageCount(){
				this.lastPage = Math.ceil(this.displayList.length / this.perPage)
				if(this.lastPage<=1){
					this.isVisible=false;
				}else{
					this.isVisible=true;
				}
			},
			setPaging(){
				this.num = this.$route.params['num']
				if(isNaN(this.num)){
					this.num = this.$store.getters.get_pnum
				}
				if(this.num <= this.lastPage){
					this.$store.commit('PAGE_MOVE',this.num)
					this.minSlice = (this.num-1)*this.perPage
					this.curPage = this.num;
				}else{
					this.minSlice = 0;
					this.curPage = 1;
					this.$store.commit('PAGE_MOVE',Number(this.curPage))
				}
			},
			setCategory(type, category){
				if(category.length < 1) {
					return ''
				} else {
					if(type == '0') {
						return 'tag ' + category.find((v)=>v.groupName == 'member').name
					} else if (type == '1') {
						return category.find((v)=>v.groupName == 'member').displayName
					}
				}
			},
			imageSet(image_obj){
				if(image_obj[0] === void 0){
					return '/assets/images/nowprinting3.gif'
				}else{
					return image_obj[0].url
				}
			},
			toDetail(item){
				this.$store.commit('CHANGE_ROOT','product')
				this.tagMove({path:'/goods/detail/'+item, params:{'hin':item}})
			},
			tagMove(to){
				// this.$gtag.query('config', '', {'page_path': to.path});
				this.$router.push({'path':to.path, 'params':to.params})
			},
			pageClick(page){
				this.fadeOut()
				setTimeout( () => {
					scrollTo(0,200)
				if(page<this.curPage){
					this.curPage=this.curPage-1
				}else if(page>this.curPage){
					this.curPage=this.curPage+1
				}
				this.minSlice = (page-1)*this.perPage;
				this.curPage = Number(page);
				this.$store.commit('PAGE_MOVE',this.curPage)
				this.tagMove({path:'/goods/'+page, params:{}})
				setTimeout(this.fadeIn,400)
				}, 500 );
			},
			preClick(){
				if(!(Number(this.curPage)<=1)){
					this.pageClick(Number(Number(this.curPage))-1);
				}
			},
			nextClick(){
				if(Number(this.curPage)!==this.lastPage){
					this.pageClick(Number(Number(this.curPage))+1);
				}
			},
			activeNum(page){
				if (Number(this.curPage)==1) {
					this.isFirst = true
					this.isLast = false
				}else if(Number(this.curPage)==this.lastPage){
					this.isLast = true
					this.isFirst = false
				}else{
					this.isFirst = false
					this.isLast = false
				}
				if(page==Number(this.curPage)){
					return 'current';
				}
			},
			chkVisiblePage(num){
				if(this.lastPage>5){
					if(this.curPage<=3){
						if(num<=5){
							return true
						}else{
							return false
						}
					}else if(this.curPage>=this.lastPage-5){
						if(num>this.lastPage-5){
							return true
						}else if(num>=(this.curPage-2) && num<=(this.curPage+2)){
							return true
						}else{
							return false
						}
					}
				}else{
					return true
				}
			},
			chkStatus(variation){
				let stock = []
				for(var v of variation){
					stock.push(this.sList.filter((n)=> n.productCode == v.productCode)[0])
				}
				if(!stock.find((k)=>k.available)){
					return true
				}else{
					return false
				}
			},
			chkNew(date){
				if(this.day.length==1){
					this.day = '0'+this.day
				}
				if(this.month.length==1){
					this.month = '0'+this.month
				}
				let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
				let release = Date.parse(date)
				if(now<=release || now<release+604800000){
					return true
				}else{
					return false
				}
			},
			fadeIn(){
				this.fadeapp = true
			},
			fadeOut(){
				this.fadeapp = false
			},
		},
	}
</script>
