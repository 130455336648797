<template>
	<footer class="st-Footer">
		<div class="logo"><img class="w-100 rendering" src="/assets/images/common/logo.png" alt="ちょこらびえんすすとあ"></div>
		<div class="content-upper">
			<div class="sns">
				<ul>
					<li><a href="https://www.youtube.com/channel/UC8XFEcGcHz6jXcaWQqfZrlA" target="_blank"><img src="/assets/images/common/footer/icon_youtube.svg" alt="YouTube"></a></li>
					<li><a href="https://www.tiktok.com/@chocorabi?" target="_blank"><img src="/assets/images/common/footer/icon_tiktok.svg" alt="TikTok"></a></li>
					<li><a href="https://twitter.com/_chofficial" target="_blank"><img src="/assets/images/common/footer/icon_twitter.svg" alt="Twitter"></a></li>
					<li><a href="https://twitcasting.tv/_chofficial/" target="_blank"><img src="/assets/images/common/footer/icon_twitcasting.svg" alt="ツイキャス"></a></li>
				</ul>
			</div>
			<div class="page-top"><a class="scroll" href="#"><span class="arrow"><img src="/assets/images/common/footer/arrow_top.svg" alt="Top"></span></a></div>
		</div>
		<div class="content-lower">
			<div class="menu">
				<ul>
					<li><a :href="cart+'/v2/help/help.aspx?ccode=ccrb'">ご利用ガイド・FAQ</a></li>
					<li><a :href="cart+'/v2/help/kiyaku.aspx?ccode=ccrb'">利用規約</a></li>
					<li><a :href="cart+'/v2/help/privacy.aspx?ccode=ccrb'">プライバシーポリシー</a></li>
					<li><a :href="cart+'/v2/help/contact.aspx?ccode=ccrb'">お問い合わせ</a></li>
				</ul>
			</div>
			<p class="copyright">© ちょこらび</p>
		</div>
	</footer>
</template>

<script>
	export default{
		data(){
			return{
				cart:process.env.VUE_APP_CARTURI,
			}
		}
	}
</script>