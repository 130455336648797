<template>
	<span>
		<div class="st-Page_Header">
			<h1 class="title">{{spTitle.displayName}}</h1>
			<div class="main-visual"><img class="w-100" :src="mainImg.imageUrl" :alt="mainImg.title"></div>
		</div>
		<div class="st-Page_Body">
			<div class="st-Goods_List">
				<ul>
					<li v-for="products in displayList.slice(minSlice, minSlice+perPage)" :key="products.index">
						<a class="canClick" @click="toDetail(products.variations[0].productCode)">
							<article>
								<div class="goods-figure">
									<div class="inner">
										<img class="rendering" :src="products.images[0].url" alt="">
									</div>
									<span class="new" v-if="chkNew(products.variations[0].releaseDate)"></span>
									<span class="soldout" v-if="chkStatus(products.variations)"></span>
								</div>
								<div class="goods-body">
									<p class="title">{{products.title}}</p>
									<p class="price">{{products.variations[0].price}}<span>円<span class="tax">（税込）</span></span></p>
									<div class="meta">
										<div class="tag" :class="products.categories.find((v)=>v.groupName == 'member').name">{{products.categories.find((v)=>v.groupName == 'member').displayName}}</div>
									</div>
								</div>
							</article>
						</a>
					</li>
				</ul>
			</div>
			<div class="st-Pagination" :hidden="!isVisible">
				<ul>
					<li :class="isFirst ? 'none':''" class="prev" @click="preClick()"><a class="canClick"><span class="icon-svg">
						<svg viewBox="0 0 8 12">
							<use xlink:href="#icon-chevron_left"></use>
						</svg>
					</span></a></li>
					<li v-if="(lastPage>5)&&(curPage>4)" class="page"><span>..</span></li>
					<template v-for="page of lastPage">
						<li :class="activeNum(page)" v-if="page>(Number(curPage)-4) && page<(Number(curPage)+4)" :key="page.index" @click="pageClick(page)" class="page"><a class="canClick">{{page}}</a></li>						
					</template>
					<li v-if="(lastPage>5)&&(curPage<(lastPage-3))" class="page"><span>..</span></li>
					<li :class="isLast ? 'none':''" @click="nextClick()" class="next"><a class="canClick"><span class="icon-svg">
						<svg viewBox="0 0 8 12">
							<use xlink:href="#icon-chevron_right"></use>
						</svg>
					</span></a></li>
				</ul>
			</div>
		</div>
	</span>
</template>

<script>
	export default{
		data(){
			return{
				host:process.env.VUE_APP_IAM,
				query:this.$route.query,
				path:this.$route.path,
				special:this.$route.params.name,
				year:new Date().getFullYear(),
				month:String(new Date().getMonth()+1),
				day:String(new Date().getDate()),
				hour:new Date().getHours(),
				minutes:new Date().getMinutes(),
				seconds:new Date().getSeconds(),
				pList:[],
				sList:[],
				cList:[],
				spTitle:'',
				displayList:[],
				mainImg:[],
				perPage:4,
				curPage:Number,
				minSlice:Number,
				num:Number,
				lastPage:Number,
				fadeapp:true,
				isVisible:true,
				isFirst:false,
				isLast:false,
			}
		},
		beforeCreate(){
			this.$store.commit('PAGE_MOVE','1')
		},
		created(){
			scrollTo(0,0)
			this.getData()
		},
		mounted(){
			document.querySelector("meta[property='og:title']").setAttribute('content',this.spTitle.displayName + ' | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]')
		},
		methods:{
			async getData(){
				try{
					const products = await this.$axios.get(this.$uri+'/product')
					const stocks = await this.$axios.get(this.$uri+'/productStock')
					const keyImage = await this.$axios.get(this.$uri+'/image?key='+this.special)
					const category = await this.$axios.get(this.$uri+'/category')
					this.pList = products.data.products
					this.displayList = this.pList.filter((v)=>v.categories.find((k)=>k.name==this.special))
					this.mainImg = keyImage.data.images[0]
					this.cList = category.data.categoryGroups.find((v)=>v.name=='special')
					this.spTitle = this.cList.categories.find((v)=>v.name == this.special)
					document.title = this.spTitle.displayName + ' | ちょこらびえんすすとあ | ちょこらびグッズ通販[公式]'
					this.sList = stocks.data
					this.displayList.sort((a,b)=>{
					if(a.variations[0].releaseDate > b.variations[0].releaseDate) return -1
						return 0
					})

					this.getPageCount()
					this.setPaging()
				}catch(e){
					if(e.response.status == 503 && e.response.headers['modd-cms-sitemaintenance'] == 1){
						window.location.href = "/maintenance/maintenance.html"
					}else{
						window.location.href="/error/error.html"
					}
				}
			},
			chkStocks(variations){
				let variationCodes = []
				let stocks = []
				let tCount = []
				for(var v of variations){
					variationCodes.push(v.productCode)
				}
				for(var c of variationCodes){
					stocks.push(this.sList.find((v)=> v.productCode == c))
				}
				tCount = stocks.find((v)=> v.available == true)
				if(tCount == void 0){
					return false
				}else{
					return true
				}
			},
			getPageCount(){
				this.lastPage = Math.ceil(this.displayList.length / this.perPage)
				if(this.lastPage<=1){
					this.isVisible=false;
				}else{
					this.isVisible=true;
				}
			},
			setPaging(){
				this.num = this.$route.params['num']
				if(isNaN(this.num)){
					this.num = this.$store.getters.get_pnum
				}
				if(this.num <= this.lastPage){
					this.$store.commit('PAGE_MOVE',this.num)
					this.minSlice = (this.num-1)*this.perPage
					this.curPage = this.num;
				}else{
					this.minSlice = 0;
					this.curPage = 1;
					this.$store.commit('PAGE_MOVE',Number(this.curPage))
				}
			},
			toDetail(item){
				this.$store.commit('CHANGE_ROOT',this.special)			
				this.tagMove({path:'/goods/detail/'+item, params:{'hin':item}})
			},
			tagMove(to){
				// this.$gtag.query('config', '', {'page_path': to.path});
				this.$router.push({'path':to.path, 'params':to.params})
			},
			pageClick(page){
				this.fadeOut()
				scrollTo(0,200)
				if(page<this.curPage){
					this.curPage=this.curPage-1
				}else if(page>this.curPage){
					this.curPage=this.curPage+1
				}
				this.minSlice = (page-1)*this.perPage;
				this.curPage = Number(page);
				this.$store.commit('PAGE_MOVE',this.curPage)
				this.tagMove({path:'/special/'+this.special+'/'+page, params:{}})
				setTimeout(this.fadeIn,400)
			},
			preClick(){
				if(!(Number(this.curPage)<=1)){
					this.pageClick(Number(Number(this.curPage))-1);
				}
			},
			nextClick(){
				if(Number(this.curPage)!==this.lastPage){
					this.pageClick(Number(Number(this.curPage))+1);
				}
			},
			activeNum(page){
				if (Number(this.curPage)==1) {
					this.isFirst = true
					this.isLast = false
				}else if(Number(this.curPage)==this.lastPage){
					this.isLast = true
					this.isFirst = false
				}else{
					this.isFirst = false
					this.isLast = false
				}
				if(page==Number(this.curPage)){
					return 'current';
				}
			},
			chkVisiblePage(num){
				if(this.lastPage>5){
					if(this.curPage<=3){
						if(num<=5){
							return true
						}else{
							return false
						}
					}else if(this.curPage>=this.lastPage-5){
						if(num>this.lastPage-5){
							return true
						}else if(num>=(this.curPage-2) && num<=(this.curPage+2)){
							return true
						}else{
							return false
						}
					}
				}else{
					return true
				}
			},
			chkStatus(variation){
				let stock = []
				for(var v of variation){
					stock.push(this.sList.filter((n)=> n.productCode == v.productCode)[0])
				}
				if(!stock.find((k)=>k.available)){
					return true
				}else{
					return false
				}
			},
			chkNew(date){
				if(this.day.length==1){
					this.day = '0'+this.day
				}
				if(this.month.length==1){
					this.month = '0'+this.month
				}
				let now = Date.parse(this.year+'-'+this.month+'-'+this.day)
				let release = Date.parse(date)
				if(now<=release || now<release+604800000){
					return true
				}else{
					return false
				}
			},
			fadeIn(){
				this.fadeapp = true
			},
			fadeOut(){
				this.fadeapp = false
			},
		},
	}
</script>